import { Box, TextField, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useState } from "react"

const LaborsSearcher = (props) => {
  const { laborCosts, addBudgetLabor } = props

  const [inputValue, setInputValue] = useState("")

  const handler = (event, newValue) => {
    if (newValue !== null) {
      const laborSelected = laborCosts.filter((item) => item.code === newValue.split(" - ")[0] 
        && item.description === newValue.split(" - ")[1]
      );
      if (laborSelected[0]) {
        addBudgetLabor(laborSelected[0])
      }
    }
    setInputValue('')
  }

  return (
    <Box style={{ marginBottom: "30px", marginTop: "20px" }}>
      <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>Mano de Obra:</Typography>
      <Autocomplete
        freeSolo
        value={''}
        inputValue={inputValue}
        onChange={handler}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        options={laborCosts.map((option) => `${option.code} - ${option.description}`)}
        style={{ minWidth: "300px", marginTop: "10px", paddingLeft: "10vh", paddingRight: "10vh" }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar Mano de Obra" variant="outlined" />
        )}
      />
    </Box>
  )
}

export default LaborsSearcher
